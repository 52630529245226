
import React, { useState, useEffect  } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from '../sidebar/SideBar';
import Logout from '../authetication/Logout';
import { useNavigate } from 'react-router-dom'; 

const Header = ({setDropdownVisible,isDropdownVisible}) => {
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    // const [isDropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate('/'); // Navigate to home page
    };

    


    useEffect(() => {
        const accessToken = localStorage.getItem('access');
        const refreshToken = localStorage.getItem('refresh');
        const storedUserInfo = localStorage.getItem('user_info');

        if (accessToken && refreshToken && storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
        }
    }, []);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const clientId = '709840142800-eqorq7htnsgb4ahrv707a6i5q7m8ul30.apps.googleusercontent.com';
    const redirectUri = 'https://takemeabroad.me/auth/google/callback';

    const handleLogin = () => {
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email%20profile&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
        window.location.href = authUrl;
    };

   

    return (
        <div className='bg-[#101828]'>
            <div className=''>
                <div className="flex items-center justify-between py-2.5 px-6 lg:px-20 xl:28">
                    <div className="flex flex-row gap-2 pointer-cursor" onClick={handleLogoClick} style={{ cursor: 'pointer' }} >
                        <div className="w-[18px] overflow-hidden">
                            <img
                                className="w-full h-full object-contain"
                                src="./Group.png"
                                alt="logo"
                            />
                        </div>
                        <div className="font-bold text-xl text-white bg-green ">norvel</div>
                    </div>
        {/* <div className="flex gap-4 hidden lg:flex">
                        <div>
                            <span className='md:text-[12px] lg:text-[12px] pt-sans-regular text-white'>Course</span>
                        </div>
                        <div>
                            <span className='md:text-[12px] lg:text-[12px] pt-sans-regular text-white opacity-80'>Forex Money Transfer</span>
                        </div>
                        <div>
                            <span className='md:text-[12px] lg:text-[12px] pt-sans-regular text-white opacity-80'>Find Accommodation</span>
                        </div>
                        <div>
                            <span className='md:text-[12px] lg:text-[12px] pt-sans-regular text-white opacity-80'>Education Loan</span>
                        </div>
                        <div>
                            <span className='md:text-[12px] lg:text-[12px] pt-sans-regular text-white opacity-80'>Student Connect</span>
                        </div>
                    </div>  */}
                 <div className="flex gap-4 items-center hidden lg:flex relative">
                        {/* <div className="flex gap-2 items-center">
                            <div className='text-[14px] pt-sans-regular text-white'>Eng</div>
                            <KeyboardArrowDownOutlinedIcon style={{ color: 'gray', fontSize: '16' }} />
                        </div> */}

                        {userInfo ? (
                            <div className="flex gap-2 items-center cursor-pointer" onClick={toggleDropdown}>
                                <img
                                    src={userInfo.user_info.profile_img || './default-profile.png'}
                                    alt="Profile"
                                    className="rounded-full w-6 h-6"
                                />
                                <KeyboardArrowDownOutlinedIcon style={{ color: 'gray', fontSize: '16' }} />
                            </div>
                        ) : (
                            <div className="flex gap-2 items-center bg-white rounded-full px-4 py-1.5 cursor-pointer" onClick={handleLogin}>
                                <PersonIcon style={{ color: 'black', fontSize: '16' }} />
                                <div className='text-[13px] pt-sans-bold text-black '>Login</div>
                            </div>
                        )}
                      
                    </div> 

                    {/* <div className='flex lg:hidden absolute right-8'>
                        <MenuIcon style={{ color: 'white' }} onClick={toggleSidebar} />
                    </div> */}
                    {isSidebarVisible && <SideBar isVisible={isSidebarVisible} onClose={toggleSidebar} />}
                </div>
            </div>
        </div>
    );
};

export default Header;

