import React, { useState, useEffect } from 'react'
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import Filters from '../filters/Filters';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useNavigate } from 'react-router-dom';
import FilterTab from '../filters/FilterTab';




const Universities = () => {
    const [universities, setUniversities] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);
    const [count, setCount] = useState(0)
    const navigate = useNavigate();
    const fetchUniversities = (url = 'home/api/v1/student-university-list/?limit=4') => {
        axiosInstance.get(url)
            .then(response => {
                setCount(response.data.count)
                setUniversities(response.data.results);
                setNextPage(response.data.next);
                setPrevPage(response.data.previous);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };

    useEffect(() => {
        fetchUniversities(); // Initial fetch on component mount
    }, []);
    const handleNext = () => {
        if (nextPage) {
            fetchUniversities(nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (prevPage) {
            fetchUniversities(prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };
    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        if (tab === 'courses') {
            navigate('/course'); // Navigate to /course when "Courses" is clicked
        } else if (tab === 'university') {
            navigate('/universities'); // Navigate to /universities when "University" is clicked
        }
    };


    return (
        <div>
            <div>
                <div>
                    <div className='px-8 md:px-20 '>

                        <div className='flex  w-full gap-6 py-10  flex-col lg:flex-row'>
                            <div>
                                <Filters />
                            </div>
                            <div className='flex flex-col gap-6'>
                                {/* <div className='flex gap-6'>
                                    <div
                                        className={`text-[12px] pt-sans-regular cursor-pointer ${location.pathname === '/course' ? 'pt-sans-bold' : ''}`}
                                        onClick={() => handleTabChange('courses')} // Trigger tab change and navigation
                                    >
                                        Courses
                                    </div>
                                    <div
                                        className={`text-[12px] pt-sans-regular cursor-pointer ${location.pathname === '/universities' ? 'pt-sans-bold' : ''}`}
                                        onClick={() => handleTabChange('university')} // Navigate to /universities when clicked
                                    >
                                        {count}  University
                                    </div>

                                    <div className='text-[12px] pt-sans-regular'>Scholarships</div>
                                </div> */}
                                <FilterTab/>
                                <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2 gap-4 md:w-full lg:w-[800px] gap-4 ">
                                    {universities.map((university) => (
                                        <div key={university.id} className="bg-white shadow-md   ">
                                            <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                                <div>
                                                    <div className="flex  justify-between item-center">
                                                        <div className='flex gap-4'>
                                                            <img src={university.logo_image} alt="University Logo" className="w-12 h-10" />
                                                            <div>
                                                                <div className='flex items-center gap-2'>
                                                                    <div className='text-[13px] pt-sans-bold '>{university.name}</div>
                                                                    {university.is_norvel_recommended && (
                                                                        <div className="pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1 whitespace-nowrap">
                                                                            Norvel Recommended
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='flex gap-2'>
                                                                    <div className='text-[12px]'>Fees Starts at:<span className='text-[10px] pt-sans-bold '>{university.pof_amount} /</span>  <span
                                                                        style={{
                                                                            background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(235,112,35,1) 100%)',
                                                                            WebkitBackgroundClip: 'text',
                                                                            WebkitTextFillColor: 'transparent'
                                                                        }}
                                                                        className='text-[11px] pt-sans-bold'>
                                                                        IELTS Required
                                                                    </span> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='hidden md:block'>
                                                            <div className="flex gap-1 ">
                                                                {/* <div>Country Logo</div> */}
                                                                <div className='pt-sans-bold text-[10px]'>{university.country?.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div>

                                                <div className='flex justify-between'>
                                                    <div className='hidden md:block' >
                                                        <div className='text-[12px]'>Location:</div>
                                                        <div className='text-[12px] pt-sans-bold  '>{university.location}</div>
                                                    </div>

                                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                                                        <div className='px-6 flex gap-2 items-center'>
                                                            <div>
                                                                <ImportContactsIcon />

                                                            </div>
                                                            <div>
                                                                <div className='text-[13px] pt-sans-regular'>Bachelor's</div>
                                                                <div className='pt-sans-bold text-[12px]'>197</div>
                                                            </div>
                                                        </div>
                                                        <div className='px-6 flex gap-2 items-center'>
                                                            <div>
                                                                <ImportContactsIcon />

                                                            </div>
                                                            <div>
                                                                <div className='text-[13px] pt-sans-regular'>Bachelor's</div>
                                                                <div className='pt-sans-bold text-[12px]'>197</div>
                                                            </div>
                                                        </div>
                                                        <div className='px-6 flex gap-2 items-center'>
                                                            <div>
                                                                <ImportContactsIcon />

                                                            </div>
                                                            <div>
                                                                <div className='text-[13px] pt-sans-regular'>Bachelor's</div>
                                                                <div className='pt-sans-bold text-[12px]'>197</div>
                                                            </div>
                                                        </div>
                                                        <div className='px-6 flex gap-2 items-center'>
                                                            <div>
                                                                <ImportContactsIcon />

                                                            </div>
                                                            <div>
                                                                <div className='text-[13px] pt-sans-regular'>Type</div>
                                                                <div className='pt-sans-bold text-[12px]'>{university.university_type}</div>
                                                            </div>
                                                        </div>
                                                        <div className='px-6 flex gap-2 items-center'>
                                                            <div>
                                                                <ImportContactsIcon />

                                                            </div>
                                                            <div>
                                                                <div className='text-[13px] pt-sans-regular'>Global Ranking</div>
                                                                <div className='pt-sans-bold text-[12px]'>{university.rank}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="border-dotted-r   flex items-center justify-center"></div> */}
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 py-6 items-center justify-center">
                            {prevPage && (
                                <button
                                    className="text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold"
                                    onClick={handlePrevious}
                                >
                                    Back
                                </button>
                            )}
                            <div className="text-black text-[12px] pt-sans-bold">{currentPage}</div>
                            {nextPage && (
                                <button
                                    className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage && 'opacity-50'}`}

                                    onClick={handleNext}
                                >
                                    NEXT
                                </button>

                            )}

                        </div>

                    </div>


                </div>






            </div>





        </div>
    )
}

export default Universities


