import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance/axiosInstance';



const FilterTab = () => {
    const [courseCount, setCourseCount] = useState(0)
    const [universityCount, setUniversityCount] = useState(0)
    const [selectedTab, setSelectedTab] = useState(null);
    const navigate = useNavigate();

    const fetchUniversities = (url = 'home/api/v1/student-university-list/?limit=4') => {
        axiosInstance.get(url)
            .then(response => {
                setUniversityCount(response.data.count)

            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };

    useEffect(() => {
        fetchUniversities(); // Initial fetch on component mount
    }, []);


    const fetchCourses = (url) => {
        axiosInstance.get(url)
            .then(response => {
                setCourseCount(response.data.count)

            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    };
    useEffect(() => {
        // Initial fetch for the first page
        fetchCourses('home/api/v1/student-course-list/?limit=4');
    }, []);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        if (tab === 'courses') {
            navigate('/course'); // Navigate to /course when "Courses" is clicked
        } else if (tab === 'university') {
            navigate('/universities'); // Navigate to /universities when "University" is clicked
        }
    };

    return (
        <div> <div className='flex gap-6'>
            <div
                className={`text-[13px] pt-sans-regular cursor-pointer underline ${location.pathname === '/course' ? 'pt-sans-bold' : ''}`}
                onClick={() => handleTabChange('courses')} // Trigger tab change and navigation
            >
                {courseCount} Courses
            </div>
            <div
                className={`text-[13px] pt-sans-regular cursor-pointer underline ${location.pathname === '/universities' ? 'pt-sans-bold' : ''}`}
                onClick={() => handleTabChange('university')} // Navigate to /universities when clicked
            >
                {universityCount}  University
            </div>

            <div className='text-[13px] pt-sans-regular underline'>Scholarships</div>
        </div></div>
    )
}

export default FilterTab
